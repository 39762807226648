import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Route, Redirect, withRouter, Switch } from 'react-router-dom';
import { Box, Container, CircularProgress } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';

import Cookies from 'universal-cookie';
import Home from './components/home/Home'

import ScrollToTop from './components/common/ScrollToTop';
import PageHeader from './components/common/Header';
import Footer from './components/common/Footer';

import './assets/css/App.css';

const Resources = lazy(() => import('./components/home/Resources'));
const About = lazy(() => import('./components/home/About'));
const Help = lazy(() => import('./components/home/Help'));
const Feedback = lazy(() => import('./components/home/Feedback'));
const Region = lazy(() => import('./components/region/Region'));

const Performer = lazy(() => import('./components/performer/Performer'));
const PerformersHome = lazy(() => import('./components/performer/PerformersHome'));
const PerformerLogin = lazy(() => import('./components/performer/PerformerLogin'));
const PasswordResetSend = lazy(() => import('./components/performer/PasswordResetSend'));
const PasswordReset = lazy(() => import('./components/performer/PasswordReset'));
const PerformerHelp = lazy(() => import('./components/performer/PerformerHelp'));
const GetCode = lazy(() => import('./components/performer/GetCode'));
const Register = lazy(() => import('./components/performer/Register'));
const CreateStepOne = lazy(() => import('./components/performer/CreateStepOne'));
const CreateStepTwo = lazy(() => import('./components/performer/CreateStepTwo'));
const PerformerLanding = lazy(() => import('./components/performer/PerformerLanding'));
const UpdatePerformer = lazy(() => import('./components/performer/UpdatePerformer'));
const PerformerPassword = lazy(() => import('./components/performer/PerformerPassword'));
const PerformerProgramList = lazy(() => import('./components/performer/PerformerProgramList'));
const AdminContactForm = lazy(() => import('./components/performer/AdminContactForm'));

const SearchResults = lazy(() => import('./components/search/SearchResults'));
const AdvancedSearch = lazy(() => import('./components/search/AdvancedSearch'));

const Program = lazy(() => import('./components/program/Program'));
const AddProgram = lazy(() => import('./components/program/AddProgram'));
const EditProgram = lazy(() => import('./components/program/EditProgram'));

const AdminLogin = lazy(() => import('./components/admin/AdminLogin'));
const AdminHelp = lazy(() => import('./components/admin/AdminHelp'));
const AdminLanding = lazy(() => import('./components/admin/AdminLanding'));
const UnapprovedPerformers = lazy(() => import('./components/admin/UnapprovedPerformers'));
const EditPerformer = lazy(() => import('./components/admin/EditPerformer'));
const AddPerfProgram = lazy(() => import('./components/admin/AddPerfProgram'));
const AdminPerformerProgramList = lazy(() => import('./components/admin/AdminPerformerProgramList'));
const EditPerfProgram = lazy(() => import('./components/admin/EditPerfProgram'));
const RegionPerformers = lazy(() => import('./components/admin/RegionPerformers'));
const SearchPerformers = lazy(() => import('./components/admin/SearchPerformers'));
const AddPerformer = lazy(() => import('./components/admin/AddPerformer'));
const EmailPerformers = lazy(() => import('./components/admin/EmailPerformers'));
const RegionWelcomeInfo = lazy(() => import('./components/admin/RegionWelcomeInfo'));
const AdminContactInfo = lazy(() => import('./components/admin/AdminContactInfo'));
const AdminPassword = lazy(() => import('./components/admin/AdminPassword'));
const EditRegionalInfo = lazy(() => import('./components/admin/EditRegionalInfo'));

const cookies = new Cookies();

// withRouter will cause funtion to be checked at every route change
const PrivateRoute = withRouter(({ component: Component, userrole, ...rest }) => {
  let loginPage = '/performers';

  if (userrole !== 'performer') {
    loginPage = '/admin/login';
  }
  
  let hasAccess = false;
  const userId = cookies.get('uid') ?? '';
  const tokenRole = cookies.get('userrole') ?? '';
  const securityLevel = cookies.get('security_level') ?? '';

  if (userrole === 'performer' && tokenRole === 'performer') {
    hasAccess = true;
  } else if (userrole === 'admin' && tokenRole === 'admin') {
    hasAccess = true;
  } else if (userrole === 'super' && tokenRole === 'admin' && securityLevel === 'super') {
    hasAccess = true;
  }

  const isAuthenticated = (
    typeof userId !== 'undefined'
    && typeof tokenRole !== 'undefined'
    && typeof securityLevel !== 'undefined'
    && hasAccess
  );

  return (
    <Route {...rest}
      render={props =>
        isAuthenticated ? (<Component {...props} />) 
        : (<Redirect to={{pathname: loginPage, state: { from: props.location } }} />)
      }
    />
  );
})

class App extends React.Component {
  render() {
    return (
      <Container fixed>
        <CssBaseline />
        <Box st={{ width: '80%' }} className="mainPanel">
          <Router>
            <ScrollToTop>
              <PageHeader />
                <div className="mainContent">
                  <Suspense fallback={<CircularProgress color="inherit" />}>
                    <Switch>
                      <Route path="/" exact component={Home} />
                      <Route path="/resources" exact component={Resources} />
                      <Route path="/about" exact component={About} />
                      <Route path="/help" exact component={Help} />
                      <Route path="/feedback" exact component={Feedback} />
                      <Route path="/region/:regionId" exact component={Region} />
                      <Route path="/search" exact component={SearchResults} />
                      <Route path="/search/advanced/:regionId" exact component={AdvancedSearch} />

                      <Route path="/program/:programId" exact component={Program} />
                      
                      <Route path="/performer/passwordReset" exact component={PasswordResetSend} />
                      <Route path="/performer/reset/:authCode" exact component={PasswordReset} />
                      <Route path="/performer/reset" exact component={PasswordResetSend} />
                      <Route path='/performer/login' exact component={PerformersHome} />
                      <PrivateRoute path="/performer/program/add" userrole="performer" exact component={AddProgram} />
                      <PrivateRoute path="/performer/program/:progId" userrole="performer" exact component={EditProgram} />
                      <PrivateRoute path="/performer/programs" userrole="performer" exact component={PerformerProgramList} />
                      <PrivateRoute path="/performer/update" userrole="performer" exact component={UpdatePerformer} />
                      <PrivateRoute path="/performer/contact" userrole="performer" exact component={AdminContactForm} />
                      <PrivateRoute path="/performer/changePassword" userrole="performer" exact component={PerformerPassword} />
                      <PrivateRoute path="/performer" userrole="performer" exact component={PerformerLanding} />

                      <Route path="/performer/:performerId" exact component={Performer} />

                      <Route path="/program/:programId" exact component={Program} />
                      
                      <Route path="/performerHelp" exact component={PerformerHelp} />
                      <Route path="/performers" exact component={PerformersHome} />
                      <Route path="/performers/login/:regionId" exact component={PerformerLogin} />
                      <Route path="/performers/login" exact component={PerformerLogin} />
                      <Route path="/performers/code" exact component={GetCode} />
                      <Route path="/performers/create/register" exact component={Register} />
                      <Route path="/performers/create/step1" exact component={CreateStepOne} />
                      <Route path="/performers/create/step2" exact component={CreateStepTwo} />

                      <Route path='/admin/login' exact component={AdminLogin} />
                      <PrivateRoute path="/admin/help" userrole="admin" exact component={AdminHelp} />
                      <PrivateRoute path="/admin/unapproved" userrole="admin" exact component={UnapprovedPerformers} />
                      <PrivateRoute path="/admin/regionPerformers" userrole="admin" exact component={RegionPerformers} />
                      <PrivateRoute path="/admin/searchPerformers" userrole="admin" exact component={SearchPerformers} />
                      <PrivateRoute path="/admin/editPerf/:perfId" userrole="admin" exact component={EditPerformer} />
                      <PrivateRoute path="/admin/addProgram/:perfId" userrole="admin" exact component={AddPerfProgram} />
                      <PrivateRoute path="/admin/perfPrograms/:perfId" userrole="admin" exact component={AdminPerformerProgramList} />
                      <PrivateRoute path="/admin/perfProgram/:progId" userrole="admin" exact component={EditPerfProgram} />
                      <PrivateRoute path="/admin/emailAllPerformers" userrole="admin" exact component={EmailPerformers} />
                      <PrivateRoute path="/admin/emailRegionalPerformers" userrole="admin" exact component={EmailPerformers} />
                      <PrivateRoute path="/admin/addPerformer" userrole="admin" exact component={AddPerformer} />
                      <PrivateRoute path="/admin/welcomeinfo" userrole="admin" exact component={RegionWelcomeInfo} />
                      <PrivateRoute path="/admin/contactInfo" userrole="admin" exact component={AdminContactInfo} />
                      <PrivateRoute path="/admin/regionalInfo" userrole="admin" exact component={EditRegionalInfo} />
                      <PrivateRoute path="/admin/changePassword" userrole="admin" exact component={AdminPassword} />
                      
                      <PrivateRoute path="/admin" userrole="admin" exact component={AdminLanding} /> 

                      <Route component={Home} />
                    </Switch>
                  </Suspense>
                </div>
              <Footer />
            </ScrollToTop>
          </Router>
        </Box>
      </Container>
    );
  }
}

export default App;
