import React from 'react';
import { Link, withRouter, matchPath } from 'react-router-dom';
import axios from 'axios';
import Divider from '@mui/material/Divider';

import NavLinks from './NavLinks';

class Footer extends React.Component {
   state = { 
      regions: [],
      curRegion: 0
   };

   static getDerivedStateFromProps(nextProps, prevState){
      let curRegion = 0;

      // check if path has regionId
      let match = matchPath(nextProps.location.pathname, {
        path: "/region/:regionId"
      });

      if (match !== null) {
         curRegion = match.params.regionId;
      } else {
         match = matchPath(nextProps.location.pathname, {
            path: "/performers/region/:regionId/login"
         });

         if (match !== null) {
            curRegion = match.params.regionId;
         }
      }

      if(curRegion!==prevState.curRegion){
        return { curRegion };
     }
     else return null;
   }

   componentDidMount() {
      this.fetchRegions();
   }

   fetchRegions = () => {
      axios.get('/api/regions/')
      .then((response) => {
         this.setState({ regions: response.data.regions });
      });
   }
   
   render() {
      return (
         <footer className="footer" id="footer">
            <div id="searchFooter" style={{ paddingBottom: '10px' }} >
               <span style={{ fontWeight: 'bold', paddingRight: '15px' }}>Search:</span> 
               { 
                  this.state.regions.map(region => (
                     <Link to={`/region/${region.id}`} className="footerLink" key ={`regionlink-${region.id}`}>{region.region}</Link>
                  ))
               }
               <Link to="/region/0" className="footerLink">All of NY</Link>
            </div>
            <Divider/>
            <NavLinks curRegion={this.state.curRegion} />
            <div className="disclaimer">
               The Performers and Programs website is supported by Federal LSTA funds, awarded to the NYS Library. 
               The NYS Library is a program of the Office of Cultural Education, NYS Education Department.
            </div>
         </footer>
      );
   }
}

export default withRouter(Footer);
