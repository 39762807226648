import React from 'react';
import mapImg from '../../assets/imgs/new_map.jpg';

class Home extends React.Component {
   render() {
      return (
         <React.Fragment>
            <img src={mapImg} alt="New York State's Performance Region map" useMap="#regions" />

            <map name="regions">
               <area style={{ cursor: 'pointer' }} alt="Search all of New York" title="Search all of New York" onClick={() => this.props.clickHandler(0)} coords="126,402,336 ,459" shape="rect" href="#" />
               {/* <area style={{ cursor: 'pointer' }} alt="Search all of New York" title="Search all of New York" onClick={() => this.props.clickHandler(0)} coords="336,402,126,459" shape="rect" href="#" /> */}
               <area style={{ cursor: 'pointer' }} alt="North Country Region: Clinton, Essex, Franklin, Jefferson, Lewis, Oswego, St. Lawrence" title="North Country Region: Clinton, Essex, Franklin, Jefferson, Lewis, Oswego, St. Lawrence" onClick={() => this.props.clickHandler(6)} coords="252,200,253,211,261,218,280,218,301,221,310,191,325,191,353,172,356,119,412,112,418,125,415,136,425,145,435,157,478,148,476,8,362,11,267,118" shape="poly" href="#" />
               <area style={{ cursor: 'pointer' }} alt="Western Region: Cattaraugus, Chautauqua, Erie, Genesee, Niagara, Orleans counties" title="Western Region: Cattaraugus, Chautauqua, Erie, Genesee, Niagara, Orleans counties" onClick={() => this.props.clickHandler(1)} coords="150,203,75,212,70,281,17,329,16,368,125,367,124,310,114,304,115,264,124,258,148,258,152,247,152,235,147,233" shape="poly" href="#" />
               <area style={{ cursor: 'pointer' }} alt="Rochester Region: Livingston, Monroe, Ontario, Wayne, Wyoming counties" title="Rochester Region: Livingston, Monroe, Ontario, Wayne, Wyoming counties" onClick={() => this.props.clickHandler(2)} coords="154,203,155,227,156,244,152,259,126,262,119,271,119,303,130,306,165,306,173,298,192,296,194,279,222,277,225,244,242,243,241,214" shape="poly" href="#" />
               <area style={{ cursor: 'pointer' }} alt="South Central Region: Allegany, Broome, Cayuga, Chemung, Chenango, Cortland, Delaware, Otsego, Schuyler, Seneca, Steuben, Tioga, Tompkins, Yates counties" title="South Central Region: Allegany, Broome, Cayuga, Chemung, Chenango, Cortland, Delaware, Otsego, Schuyler, Seneca, Steuben, Tioga, Tompkins, Yates counties" onClick={() => this.props.clickHandler(4)} coords="246,214,246,247,230,250,227,280,201,283,200,300,176,307,167,311,133,313,130,366,335,368,357,381,403,345,405,320,388,303,390,293,389,269,381,263,376,271,364,266,353,268,348,279,304,288,294,276,287,278,271,278,265,267,258,263,256,223" shape="poly" href="#" />
               <area style={{ cursor: 'pointer' }} alt="Central Region: Herkimer, Madison, Oneida, Onondaga counties" title="Central Region: Herkimer, Madison, Oneida, Onondaga counties" onClick={() => this.props.clickHandler(3)} coords="263,224,263,258,281,274,299,273,309,282,346,274,347,264,383,255,381,239,385,225,373,206,377,182,375,125,361,125,357,178,334,197,314,194,308,223" shape="poly" href="#" />
               <area style={{ cursor: 'pointer' }} alt="Capital District Region: Albany, Fulton, Hamilton, Montgomery, Rensselaer, Saratoga, Schenectady, Schoharie, Warren, Washington counties" title="Capital District Region: Albany, Fulton, Hamilton, Montgomery, Rensselaer, Saratoga, Schenectady, Schoharie, Warren, Washington counties" onClick={() => this.props.clickHandler(5)} coords="379,123,380,208,390,226,385,244,390,263,398,286,395,299,408,318,487,298,489,177,479,154,434,160,411,136,413,128,410,117" shape="poly" href="#" />
               <area style={{ cursor: 'pointer' }} alt="Southeastern Region: Columbia, Dutchess, Greene, Orange, Putnam, Rockland, Sullivan, Ulster counties" title="Southeastern Region: Columbia, Dutchess, Greene, Orange, Putnam, Rockland, Sullivan, Ulster counties" onClick={() => this.props.clickHandler(8)} coords="411,323,410,345,362,386,364,420,443,471,448,457,442,440,476,433,487,303" shape="poly" href="#" />
               <area style={{ cursor: 'pointer' }} alt="Metro Region: Bronx, Kings, New York, Queens, Richmond, Westchester counties" title="Metro Region: Bronx, Kings, New York, Queens, Richmond, Westchester counties" onClick={() => this.props.clickHandler(7)} coords="448,443,453,457,450,499,422,532,430,537,461,528,465,509,463,501,471,482,479,436" shape="poly" href="#" />
               <area style={{ cursor: 'pointer' }} alt="Long Island Region: Nassau, Suffolk counties" title="Long Island Region: Nassau, Suffolk counties" onClick={() => this.props.clickHandler(9)} coords="468,501,471,512,468,524,503,521,603,467,581,447,482,481" shape="poly" href="#" />
            </map>
         </React.Fragment>
      );
   }
}

export default Home;
