import { Link } from 'react-router-dom';
import headerImg from '../../assets/imgs/header_newSummerReading.png';
// import adminHeaderImg from '../../assets/imgs/head-yellow.jpg';

const PageHeader = () => (
   <header style={{ textAlign: 'center' }}>
      <Link to="/">
         <img src={headerImg} alt="Performers and Programs Home. A database resource of entertaining & educational programs. Summer Reading at New York Libraries" className="siteBanner" />
         {/* <img src={adminHeaderImg} alt="Performers and Programs Home" className="adminBanner" style=
         {{ display: 'none' }} /> */}
      </Link>
   </header>
)

export default PageHeader;
