import React from 'react';
import { Link } from 'react-router-dom';

class NavLinks extends React.Component {
   render() {
      return (
         <div style={{ paddingTop: '10px', paddingBottom: '10px' }}>
            <Link to="/" className="footerNavLink">Home Page</Link> | 
            <Link to="/resources" className="footerNavLink">Resources</Link> | 
            <Link to="/about" className="footerNavLink">About this Site</Link> | 
            <Link to="/performers" className="footerNavLink">Performer Login & Signup</Link> | 
            <Link to="/help" className="footerNavLink">Help</Link> | 
            <Link to="/feedback" className="footerNavLink">Feedback</Link>
         </div>
      );
   }
}

export default NavLinks;
