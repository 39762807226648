import React from 'react';
import Map from './Map';

class Home extends React.Component {
   clickHandler = (regionId) => {
      this.props.history.push({
         pathname: `/region/${regionId}`
      });
   }

   render() {
      return (
         <div>
            <h1 className="centeredHeader">
                  Welcome to the NEW Statewide<br />
                  New York State Performers &amp; Programs Database
            </h1>
            <div style={{ textAlign: "center", paddingTop: '15px' }}>
               To start your search for performers and program presenters in your region or statewide, 
               select a region from the map below.
               <div style={{ paddingTop: '20px' }}>
                  <Map clickHandler={this.clickHandler} />
               </div>
            </div>
         </div>
      );
   }
}

export default Home;
